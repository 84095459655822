import { DIMENSIONS } from '@canalplus/mycanal-commons';
import { Heading, ImageType } from '@canalplus/mycanal-sharedcomponent';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import { CoverV5Data } from '../IdentityCover/types';
import TemplateHeaderCanal from '../TemplateHeader/TemplateHeader';
import styles from './HeadingIdentityV5.css';

const cx = classNames.bind(styles);

type HeadingIdentityV5Props = { cover: CoverV5Data; title?: string };

function HeadingIdentityV5({
  cover: { urlLogoType, altLogoType, urlLogoChannel, altLogoChannel, description, image },
  title,
}: HeadingIdentityV5Props): JSX.Element {
  const isTvDevice = useSelector(displayTVModeSelector);
  const isCoverImage = !!image;

  const isLogoDefined = !!urlLogoType || !!urlLogoChannel;

  return (
    <div
      className={cx('headingIdentityV5', { 'headingIdentityV5--withcover': isCoverImage })}
      data-testid="HeadingIdentityV5"
    >
      {title && !isLogoDefined && (
        <TemplateHeaderCanal
          title={title}
          displayBigTitle
          theme={{
            templateHeader__title: cx('headingIdentityV5__title'),
          }}
        />
      )}
      {urlLogoType && (
        <div className={cx('headingIdentityV5__logo', 'headingIdentityV5__logo--logotype')}>
          <ImageType
            URLImage={{ default: urlLogoType }}
            className={cx('headingIdentityV5__logo__image')}
            altImage={altLogoType}
            dimensions={DIMENSIONS.LOGOTYPE_IDENTITY_V5}
            isTvDevice={isTvDevice}
            isArtDirection
          />
        </div>
      )}
      {urlLogoChannel && (
        <div className={cx('headingIdentityV5__logo', 'headingIdentityV5__logo--logochannel')}>
          <ImageType
            URLImage={{ default: urlLogoChannel }}
            className={cx('headingIdentityV5__logo__image')}
            altImage={altLogoChannel}
            dimensions={DIMENSIONS.LOGO_CHANNEL_IDENTITY_V5}
            isTvDevice={isTvDevice}
            isArtDirection
          />
        </div>
      )}
      {description && (
        <div className={cx('headingIdentityV5__subtitle')}>
          <Heading text={description} level={3} />
        </div>
      )}
    </div>
  );
}

export default HeadingIdentityV5;
