import { DeviceToImageUrl } from '@canalplus/dive';
import { ImageTypeDimensions, PosterProps } from '@canalplus/mycanal-sharedcomponent';
import { memo, useMemo } from 'react';
import type { ContentStrateV5 } from '../../templates/LandingV5/data/formatter';
import type { BannerContent } from '../Banner/types';
import { CoverData } from '../IdentityCover/types';
import Linker from '../Linker/Linker';
import { PosterConnected } from '../Poster/PosterConnected';
import styles from './CoverImage.css';
import { pageNotFound } from './constants';

export type CoverImageProps = {
  borderRadius?: boolean;
  classNames?: {
    poster?: string;
    cover?: string;
    children?: string;
    hidden?: string;
    showcase?: string;
  };
  children?: React.ReactNode;
  dimensions: ImageTypeDimensions;
  displayName?: string;
  image?: DeviceToImageUrl;
  isArtDirection?: boolean;
  isBackgroundTransparent?: boolean;
  isDesktopOnly?: boolean;
  isMobileOnly?: boolean;
  isTvDevice?: boolean;
  label?: string;
  loading?: PosterProps['loading'];
  onClickData?: ContentStrateV5 | CoverData | BannerContent;
  title?: string;
  video?: string;
};

/**
 * CoverImage
 *
 * Renders a cover image using `<Poster />`, wrapped inside `<Linker />` when `onClickData` is available
 *
 * @param borderRadius                      Displays poster with border radius
 * @param classNames                        Object with themes `className`
 * @param dimensions                        Single object or composed object with image dimensions for one or all formats
 * @param displayName                       Secondary `alt` image attribute (if needed)
 * @param image                             Object with default URL (required) and mobile URLs (optional)
 * @param isArtDirection                    Enables responsive images using `<picture>` and viewport specific `<source>`
 * @param isBackgroundTransparent           Whether background is transparent or not (no placeholder)
 * @param loading                           Image loading strategy
 * @param title                             `title` to be used as image `alt` and to pass down to <Link> component
 * @param video                             Video `url` attribute
 */
function CoverImage({
  borderRadius = false,
  children = null,
  classNames = {},
  dimensions,
  displayName = '',
  image = { default: '' },
  isArtDirection = false,
  isBackgroundTransparent = false,
  isDesktopOnly,
  isMobileOnly,
  isTvDevice = false,
  label = '',
  loading = 'lazy',
  onClickData,
  title = '',
  video = '',
}: CoverImageProps): JSX.Element {
  const component = useMemo(
    () => (
      <PosterConnected
        image={image}
        video={video}
        altImage={!!title && title !== pageNotFound ? title : label} // TODO remove this check when HODOR will return the right value
        displayName={displayName}
        borderRadius={borderRadius}
        theme={classNames}
        isBackgroundTransparent={isBackgroundTransparent}
        dimensions={dimensions}
        loading={loading}
        isDesktopOnly={isDesktopOnly}
        isMobileOnly={isMobileOnly}
        isTvDevice={isTvDevice}
        isArtDirection={isArtDirection}
      >
        {children}
      </PosterConnected>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [borderRadius, children, classNames, dimensions, displayName, image, isBackgroundTransparent, loading, title, video]
  );

  const { onClick, ...restOnClick } = onClickData || {};

  return onClick ? (
    <Linker
      className={styles.coverImage__itemLink}
      data={{
        mainOnClick: onClick,
        ...('contentID' in restOnClick && { contentID: restOnClick.contentID }),
        ...('context' in restOnClick && { context: restOnClick.context }),
      }}
      title={title}
    >
      {component}
    </Linker>
  ) : (
    <div data-tv-focusable>{component}</div>
  );
}

CoverImage.displayName = 'CoverImage';

export default memo(CoverImage);
