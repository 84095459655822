import { Poster, PosterProps } from '@canalplus/mycanal-sharedcomponent';
import { useSelector } from 'react-redux';
import {
  imageQualityPercentageSelector,
  maxPosterRatioSelector,
} from '../../selectors/application/application-selectors';
import { imageQualityPercentageBySettingsSelector } from '../../store/slices/user-selectors';

export function PosterConnected(props: Omit<PosterProps, 'imageQualityPercentage' | 'maxImageRatio'>): JSX.Element {
  const imageQualityPercentageBySettings = useSelector(imageQualityPercentageBySettingsSelector);
  const imageQualityPercentage = useSelector(imageQualityPercentageSelector);
  const maxImageRatio = useSelector(maxPosterRatioSelector);

  return (
    <Poster
      {...props}
      imageQualityPercentage={imageQualityPercentageBySettings || imageQualityPercentage}
      maxImageRatio={maxImageRatio}
    />
  );
}
